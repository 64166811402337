
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


:root {
    --main-menu-color: #002b46;
    --main-bg-color: rgb(240, 240, 240);
    --main-police-color: rgb(15, 16, 56);
    --main-title-police-color: rgb(245, 245, 245);
    --main-footer-color: rgb(37, 37, 37);
    --main-alt-color: #fd8a41;
   

}

body {
    margin: 0px;
    padding: 0px;
    background-color: black;
    background: var(--main-bg-color);
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  font-style: normal;
}

nav>ul {
    list-style-type: none;
    margin: 20px;
    padding: 0;
}

nav>ul li:hover {
    text-decoration: none;
}



nav li {
    float: left;

    font-family: 'Open Sans', sans-serif;
    text-align: center;
    font-size: 16px;
    margin-left: 20px;
    margin-right: 20px;
}

nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    margin-left: 40px;
    margin-right: 40px;


}

nav a {
    display: block;
    text-decoration: none;
    color: black;
    border-bottom: 2px solid transparent;
    padding: 10px 0px;

}

header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    height: 120px;
}

header img {
    max-width: 300px;
    height: auto;
}

header .search {
    margin-left: 20px;
    margin-right: 20px;
}

header .search>form input {
    width: 150px;
    height: 31px;
    border-radius: 5px;
    border-bottom: 1px solid var(--main-menu-color);
    border-left: 1px solid var(--main-menu-color);
    border-top: 1px solid var(--main-menu-color);
}

.search {
    display: flex;
    align-items: center;
    justify-content: center;
}

.search>img {
    width: 25px;
    height: 25px;
    padding: 4px;
    background-color: rgb(255, 255, 255);
    border-right: 1px solid var(--main-menu-color);
    border-top: 1px solid var(--main-menu-color);
    border-bottom: 1px solid var(--main-menu-color);
}

.conn {
    display: flex;
    flex-direction: row;
    height: 70px;
}

.conn img {
    width: 50px;
    height: auto;
    margin-left: 20px;
    margin-right: 20px;
}

.conn>p {
    display: inline;
    margin-left: 10px;
}

.conn-child {
    display: flex;
    flex-direction: column;
}

.conn-child>p {
    margin: 0;
    width: 50px;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
}

.conn-child-img {
    margin: 0;
}

.conn-child-img>p {
    position: relative;
    text-align: center;
    z-index: 200;
    bottom: 65px;
    left: 14px;
    color: white;
    font-size: 0.9em;
}

.conn-child-img2>p {
    position: relative;
    text-align: center;
    z-index: 200;
    bottom: 63px;
}

.item {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 40px;
}

.item-info {
    margin-left: 30px;
}

.arch-item-list {

    min-width: 560px;
}

.item-detail-group {
    display: flex;
}

.item-detail-label>p {
    text-align: end;
    margin-right: 10px;
    width: 120px;
}

.item-description {
    text-align: justify;
    width : 55%;
}

.item-content {
    display: flex;
    margin-top: 30px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
}

.item-detail {
    display: flex;
}

.item>button {
    margin: 10px;
}

.item-img img {
    width: 200px;
    height: 200px;
    margin-right: 30px;
    border-radius: 8px;
    object-fit: cover;
}

.document-description {
    margin-top: 100px;
}

.separator {
    height: 10px;
    background-color: red;
    border: none;
}

section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: white;

}

.section-light {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: rgb(218, 218, 218);
    padding-bottom: 20px;
}


.arch-section {
    display: flex;
    flex-direction: row;

    justify-content: center;
    width: 70%;
    min-height: 800px;
    margin-top: 100px;
    margin-bottom: 100px;

}

.landing-card-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 70%;
    flex-wrap: wrap;
    margin-bottom: 100px;

}

.landing-card {
    width: 300px;

   
    margin: 10px;
    text-align: center;
    height: 400px;
    background-size: 300px auto;
    flex-wrap: wrap;
    text-decoration: none !important;
    border: 1px rgb(216, 216, 216) solid;
    border-radius : 8px;
   
    box-shadow: inset 0 0 4em 0.3em #1f1e1e;
}

.landing-card:hover {

    -webkit-filter: sepia(100%);
	filter: sepia(100%);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
   
   
}

.landing-small-card {
    display: flex;
    align-items: center;
    
    width: 350px;
    background-color: rgb(218, 218, 218);
    margin: 3px;
    text-align: center;
    height: 100px;
    background-size: 350px auto;
    flex-wrap: wrap;
    text-decoration: none !important;
    border: 1px rgb(216, 216, 216) solid;
    box-shadow: 10px 5px 5px lightgray;
    -webkit-filter: sepia(0);
	filter: sepia(0);
    background-position: center;
    box-shadow: inset 0 0 2em 0.2em #1f1e1e;
    border-radius : 8px;
}


.landing-small-card:hover {
   
  

    -webkit-filter: sepia(100%);
	filter: sepia(100%);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.title{

    margin-top : 80px;
  margin-bottom : 80px;
}

.propo-text
{
    margin-top : 100px;
    margin-bottom : 100px;
    width : 600px;
    text-align: justify;
    font-family: "Roboto", sans-serif;

}

.title>h2{
    font-size: 40px;
    font-weight: 700;
    font-style: normal;
    font-family: "Dancing Script", cursive;
  font-style: normal;
  font-weight: bold;
  text-align: center;

}

.title>p{
 color: #3f1d02;
 font-family: "Roboto", sans-serif;
}



a:link {
    text-decoration: none !important;
}

.landing-title>h2 {
    color: white;
    text-shadow: 1px 1px 5px rgb(0, 0, 0), 0 0 1em rgb(0, 0, 0), 0 0 0.2em black !important;
    font-size: 35px;
   /* background-color: rgb(226, 179, 143);*/

    padding-top: 10px;
    padding-bottom : 10px;
/*font-family: "Roboto", sans-serif;*/
    font-weight: 700;
    font-style: normal;
    font-family: "Dancing Script", cursive;
    
  font-style: normal;
  font-weight: bold;
  text-shadow: 1px 1px 1px rgb(0, 0, 0);

}

.landing-small-title>h2 {
 
    color: rgb(255, 255, 255);
    font-size: 35px;
    margin-left : 30px;
    font-family: "Dancing Script", cursive;
    
    font-weight: bold;
    text-shadow: 1px 1px 5px rgb(0, 0, 0), 0 0 1em rgb(0, 0, 0), 0 0 0.2em black !important;
}



.landing-text>p {
    color: white;
  
}



@media screen and (max-width: 1600px) {
    .arch-section {
        width: 90%;
        min-height: 800px;
    }
}

.arch-nav-menu {
    width: 250px;

    min-height: 800px;


    border-right: 1px solid lightgray;

}

.arch-content {
    width: 100%;
    margin-bottom: 100px;

}

.arch-content-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    margin-bottom: 30px;
}

.arch-content-nav-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    width: 300px;
   
}

.arch-content-nav-search>input {
    
    border-radius: 5px 0px 0px 5px;
 }

.arch-content-nav-search>span {
   margin-left: 0px;
   border-radius: 0px 5px 5px 0px;
}

.arch-content-nav-select1 {
    width: 230px;
    margin-right: 30px;
}

.arch-content-nav-select2 {
    width: 380px;
    margin-right: 30px;
   
}




.document-content h3 {
    margin-bottom: 20px;
    margin-top: 40px;
}

.document-content h4 {
    margin: 20px;
}

.arch-menu-check {
    text-align: center;



    padding-bottom: 20px;
    margin-bottom: 20px;
}

.file-content {
    margin-right: 20px;
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
}



.file-content a {
    font-size: 30px;
}



.file-name p {
    margin: 0;
    margin-right: 20px;
    font-weight: bold;
}

.file-description p {
    margin: 0;
    margin-right: 20px;
    font-weight: normal;
    font-size: 14px;
}

.file-button {}

.landing-section {
    margin-top: 100px;
    margin-bottom: 100px;
    font-family: 'Roboto', sans-serif;
}


.landing-slider {
    width : 100%;
    height : 600px;
    background-image: url("../../public/Pitet.jpg");
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: inset 0 0 15em 0.3em #1f1e1e;
   
}


.landing-slider-text{
    margin-top : 150px;
    margin-left : 25%;
    width : 35%;
    color: white;
    text-shadow: 1px 1px 10px rgb(0, 0, 0), 0 0 1em rgb(0, 0, 0), 0 0 0.2em black !important;
    text-align: justify;

}

.landing-slider-text>h1{
    font-weight: bold;
    font-size: 40px;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
}

.landing-slider-text>p{
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    text-shadow: 1px 1px 5px rgb(0, 0, 0), 0 0 1em rgb(0, 0, 0), 0 0 0.2em black !important;

}



.arch-menu-catCheck {
    margin-left: 0px;

}

.breadcrumb-section {
    background-color: rgb(19, 36, 13);
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-image: url("../../public/room.png");
    background-position: 20% 55%;
    border: 1px rgb(216, 216, 216) solid;
    box-shadow: inset 0 0 10em 2em #000000;
    font-weight: bold;

    text-shadow: 1px 1px 5px rgb(0, 0, 0), 0 0 2em rgb(0, 0, 0), 0 0 0.2em black !important;
}

.breadcrumb-content {

    width: 50%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;

}

.breadcrumb-element {
    flex-grow: 1;
    margin: 30px;
}

.breadcrumb-element>p {

    font-weight: normal;
    margin-right : 50px;
}

.breadcrumb-section a {
    color: white;
    text-align: end;
}



.breadcrumb-section a {
    text-decoration: none;
}

.dual {
    display: flex;
    justify-content: center;
    background-color: var(--main-bg-color);
}

.prodlist {
    display: flex;
    width: 65%;
    min-height: 800px;
    background-color: white;
    margin-bottom: 40px;
    margin-top: 40px;
}

footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(20, 23, 51);
    padding-top: 20px;

}

footer a {
    text-decoration: none;
    color: white;
    border-bottom: 1px solid white;
}

footer>p {
    text-align: center;
    color: rgb(236, 236, 236);
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0;
}

.footer-container {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;

    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: rgb(19, 36, 13);
}

.footer-sect {
    display: flex;
    flex-direction: row;
    text-align: center;

}

.footer-sect>h3 {
    text-align: left;
    color: rgb(236, 236, 236);
    margin-left: 10px;
    text-decoration: underline;
    text-underline-position: under;
}

.footer-sect>p {
    margin: 0;
    margin-left: 10px;
    color: rgb(236, 236, 236);
}

.footer-sect>a {
    margin-left: 25px;
    margin-right: 25px;
    text-decoration: none;
    color: rgb(236, 236, 236);
    margin-top: 2px;
    margin-bottom: 2px;

}

.footer-sect img {
    height: 60px;
    width: 60px;
    margin-top: 15px;
    margin-left: 14%;
}

.footer-contact {
    margin-top: 20px;
}

.footer-contact-type img {
    float: left;
    height: 40px;
    width: 40px;
    margin: 0;
    margin-left: 10px;
}

.footer-contact-type>p {
    margin: 0;
    margin-left: 60px;
    color: rgb(236, 236, 236);
    padding-top: 8px;
    padding-bottom: 20px;
}

.footer-sect>a:hover {
    text-decoration: underline;
}

.footer-sect-alt>h3 {
    text-align: left;
    color: rgb(236, 236, 236);
    margin-left: 10px;
    text-decoration: underline;
    text-underline-position: under;
    text-align: center;
}

.footer-sect-alt img {
    height: 80px;
    width: 80px;
    margin: 0;
    margin-left: 50px;
}

.footer-sect-alt {
    display: flex;
    flex-direction: column;
}

.slide-container {
    width: 100%;
    margin: auto;
    background-color: rgb(0, 0, 0);
    align-items: center;
    justify-content: center;
}

.slide-container>h3 {
    text-align: center;
}

.each-slide>div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 300px;
}

.each-slide span {
    font-size: 20px;
    background: #efefef;
    text-align: center;
}

.each-fade {
    display: flex;
}

.each-fade .image-container {
    width: 100%;
    overflow: hidden;
}

.each-fade .image-container img {
    width: 100%;
}

.each-fade h2 {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: #adceed;
}

.selection {
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    padding-bottom: 100px;
}

.selection>h1 {
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    background-color: var(--main-menu-color);
    color: var(--main-title-police-color);
    font-weight: bold;
    font-size: 2.5em;
    margin-bottom: 50px;
}

.pres {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    width: 100%;
    background-color: rgb(240, 240, 240);
    padding-top: 50px;
    padding-bottom: 100px;
}

.art {
    width: 22%;
    min-width: 300px;
    height: 250px;
    color: black;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 60px;
    border-bottom: 1px solid var(--main-alt-color);
}

.art>a {
    padding: 10px;
    padding-right: 15px;
    width: 40%;
    padding-left: 15px;
    text-decoration: none;
    font-size: 1em;
    font-weight: bold;
    color: var(--main-menu-color);
    border: 1px solid var(--main-menu-color);
    border-radius: 15px;
    margin-bottom: 30px;
    margin-top: 30px;
    text-align: center;
}

.art>a:hover {
    border: 1px solid rgb(68, 49, 9);
    color: rgb(0, 0, 0);
}

.art>h2 {
    font-family: 'Oswald', sans-serif;
    margin: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 5px;
    font-size: 1.5em;
    background-color: var(--main-menu-color);
    color: var(--main-title-police-color);
    width: 100%;
    font-weight: bold;
}

.art>p {
    margin: 0px;
    text-align: left;
    width: 92%;
    font-family: 'Open sans', sans-serif;
    font-size: 0.9em;
}

.catsel {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.produitmini img {
    height: 170px;
    margin-bottom: 20px;
}

.produitmini {
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    width: 300px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
}

.produitmini>a {
    text-align: center;
}

.produitmini>p {
    margin: 5px;
    font-size: 0.9em;
    padding-left: 3px;
    width: 70%;
    text-align: left;
    border-bottom: 1px solid var(--main-alt-color);
    color: var(--main-police-color);
}

.produitmini .achat {
    background-color: var(--main-alt-color);
    display: flex;
    justify-content: space-between;
    color: white;
}

.produitmini .achat>p {
    padding-left: 10px;
}

.produitmini>h2 {
    text-align: center;
    border-bottom: 1px solid var(--main-alt-color);
    width: 80%;
    padding: 5px;
}

.produitminilistImg {
    margin-left: 10px;
    width: 22%;
}

.produitminilist {
    height: 150px;
    width: 98%;
    border-bottom: 1px solid var(--main-alt-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 10px;
    background-color: white;
}

.produitminilist img {
    width: 55%;
    height: auto;
}

.produitminilistText {
    width: 507px;
    text-decoration: none;
}

.produitminilistText>a {
    text-decoration: none;
    color: rgb(28, 29, 124);
    font-size: 1.2em;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}

.produitminilistText>p {
    font-family: 'Roboto', sans-serif;
    font-size: 0.9em;
}

.produitminilistText>a:visited {
    text-decoration: none;
}

.produitminilistPrice {
    height: 100%;
    width: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
}

.produitminiPrice {
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
}

.produitminilistPrice>p {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0;
    margin-bottom: 25px;
    padding: 0;
    color: rgb(28, 29, 124);
    font-family: 'Oswald', sans-serif;
}

.produitminiPrice>p {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0;
    margin-bottom: 25px;
    padding: 0;
    color: rgb(28, 29, 124);
    font-family: 'Oswald', sans-serif;
}

.produitminilistPrice>a {
    padding: 10px;
    width: 140px;
    background-color: rgb(255, 185, 35);
    text-decoration: none;
    font-size: 1em;
    font-weight: bold;
    color: rgb(0, 0, 0);
    border-radius: 5px;
    border: 1px solid rgb(204, 204, 204);
}

.produitminilistPrice>a:hover {
    text-decoration: none;
    background-color: rgb(212, 154, 28);
    animation-name: configureButton;
    animation-duration: 0.4s;
}

.produitminiPrice>a {
    padding: 10px;
    width: 150px;
    background-color: rgb(255, 185, 35);
    text-decoration: none;
    font-size: 1em;
    font-weight: bold;
    color: rgb(0, 0, 0);
    border-radius: 5px;
    border: 1px solid rgb(204, 204, 204);
}

.produitminiPrice>a:hover {
    text-decoration: none;
    background-color: rgb(212, 154, 28);
    animation-name: configureButton;
    animation-duration: 0.4s;
}

.produitminilistPrice>a:visited {
    text-decoration: none;
}

.produitminiPrice>a:visited {
    text-decoration: none;
}

@keyframes configureButton {
    from {
        background-color: rgb(255, 185, 35);
    }

    to {
        background-color: rgb(212, 154, 28);
    }
}

.miniliststock {
    width: 100px;
    height: 50px;
    margin-right: 50px;
    text-align: center;
    border: 1px solid rgb(180, 179, 179);
}

.miniliststock>p {
    font-weight: bold;
}

.ficheminiliststock {
    width: 150px;
    height: 50px;
    text-align: center;
    margin-bottom: 25px;
    background-color: white;
    border: 1px solid rgb(180, 179, 179);
    box-shadow: 2px 2px 0px rgb(133, 133, 133);
}

.ficheminiliststock>p {
    font-weight: bold;
}

.panierlist {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
}

.produitcart {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-bottom: 1px solid rgb(180, 179, 179);
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    margin: 0;
}

.produitcart:last-child {
    border-bottom: 0px solid rgb(180, 179, 179);
}

.produitcart>img {
    width: 100px;
    min-width: 100px;
    margin: 15px;
    height: auto;
}

.referCart {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    flex-grow: 1;
}

.referCart>p {
    font-size: 0.9em;
    color: var(--main-police-color);
}

.deleteicon img {
    margin-top: 5px;
    max-width: 50%;
    height: auto;
}

.deleteicon img:hover {
    cursor: pointer;
    background-color: rgb(211, 211, 211);
}

.productlist {
    display: flex;
    margin-bottom: 20px;
    justify-content: top;
    width: 100%;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.priceprodcart {
    width: 280px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    margin-right: 15px;
    margin-left: 50px;
    font-family: "Oswald", sans-serif;
    font-size: 0.9em;
    color: var(--main-police-color);
}

.priceprodcart>select {
    width: 60px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 15px;
}

.totalcart {
    width: 100%;
    text-align: right;
}

.totalcart>p {
    font-size: 20px;
    font-weight: bold;
    margin-right: 225px;
}

.totalcartBuy {
    text-align: center;
    width: 220px;
    background-color: rgb(240, 240, 240);
    border-left: 1px solid rgb(211, 211, 211);
}

.totalcartBuy>p {
    font-size: 1.4em;
    color: rgb(28, 29, 124);
    font-family: "Oswald", sans-serif;
    margin-top: 10px;
    margin-bottom: 50px;
}

.totalcartBuy>a {
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: rgb(255, 185, 35);
    text-decoration: none;
    font-size: 1em;
    font-weight: bold;
    color: var(--main-police-color);
    border-radius: 5px;
}

.totalcartBuy>a:hover {
    background-color: rgb(255, 170, 0);
}

.totalcartBuy>h3 {
    margin-top: 40px;
    margin-bottom: 10px;
    font-size: 1.3em;
    color: var(--main-police-color);
    font-family: "Oswald", sans-serif;
}

.recap {
    display: flex;
    border: 1px solid rgb(211, 211, 211);
    margin-left: 5%;
    margin-right: 5%;
    min-height: 500px;
    margin-bottom: 20px;
}

.recap-items {
    flex-grow: 1;
}

.recap-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.recap-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 40px;
}

.recap-button-buy>a {
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-right: 55px;
    margin-left: 55px;
    background-color: rgb(255, 185, 35);
    text-decoration: none;
    font-size: 1em;
    font-weight: bold;
    color: var(--main-police-color);
    border-radius: 5px;
}

.recap-button-buy>a:hover {
    background-color: rgb(255, 170, 0);
}

.recap-button>a {
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-right: 55px;
    margin-left: 55px;
    background-color: rgb(185, 185, 185);
    text-decoration: none;
    font-size: 1em;
    font-weight: bold;
    color: var(--main-police-color);
    border-radius: 5px;
}

.recap-button>a:hover {
    background-color: rgb(146, 146, 146);
}

.titleList {
    height: 170px;
    background: linear-gradient(rgb(235, 235, 235), rgb(214, 214, 214));
    padding: 0px;
    margin: 0px;
    display: flex;
    color: rgb(0, 0, 0);
    justify-content: space-between;
    align-items: center;
}

.titleList>h1 {
    padding-left: 20%;
}

.titleList>p {
    padding-right: 20%;
}

.titleList>p>a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    padding-right: 10px;
    padding-left: 10px;
}

.titleList>p>a:hover {
    text-decoration: underline;
}

.titleList>p>a:visited {
    text-decoration: none;
}

.categorieList {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 20%;
    justify-content: center;
    align-items: center;
    padding-right: 20%;
}

.categorie {
    width: 300px;
    height: 100px;
    background: linear-gradient(rgb(255, 255, 255), rgb(241, 241, 241));
    display: flex;
    align-items: center;
    border-radius: 4px;
    color: rgb(36, 28, 141);
    margin-right: 10px;
    margin-bottom: 10px;
}

.categorie>h2 {
    font-weight: bold;
    font-size: 1.1em;
    text-decoration: none;
}

.categorie>img {
    margin-right: 25px;
    max-width: 30%;
    height: auto;
}

.logform {
    display: flex;
    width: 500px;
    align-items: center;
    flex-direction: column;
    border: 1px solid lightgray;
    margin-top: 100px;
    margin-bottom: 100px;
}

.logform>form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;
    padding-top: 20px;
}

.logform>form>input[type=text] {
    margin: 5px;
    height: 40px;
    width: 300px;
    border: 1px solid lightgray;
    padding-left: 10px;
}

.logform>form>input[type=password] {
    margin: 5px;
    height: 40px;
    width: 300px;
    border: 1px solid lightgray;
    padding-left: 10px;
}

.logform>form>input[type=submit] {
    margin: 10px;
    width: 220px;
    background-color: rgb(202, 202, 202);
    border-radius: 7px;
    font-size: 1em;
    padding-top: 13px;
    padding-bottom: 13px;
    margin-top: 20px;
}

.logform>form>input[type=submit]:hover {
    background-color: rgb(153, 153, 153);
}

.logform>a {
    margin: 10px;
    width: 220px;
    background-color: rgb(202, 202, 202);
    text-align: center;
    border-radius: 7px;
    margin-bottom: 40px;
    text-decoration: none;
    color: black;
    font-size: 1em;
    padding-top: 13px;
    padding-bottom: 13px;
    margin-top: 20px;
}

.logform>a:hover {
    background-color: rgb(153, 153, 153);
}

.logform>h2 {
    background-color: var(--main-menu-color);
    height: 50px;
    line-height: 50px;
    width: 100%;
    text-align: center;
    color: white;
    margin: 0;
    margin-bottom: 20px;
}

.creaform {
    display: flex;
    width: 30%;
    align-items: center;
    flex-direction: column;
    border: 1px solid lightgray;
    margin-top: 100px;
    margin-bottom: 100px;
}

.creaform>form {
    margin-left: 20%;
}

.creaform>form>h3 {
    margin: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 300px;
    text-align: center;
}

.creaform>form>input[type=text],
input[type=password] {
    margin: 5px;
    height: 35px;
    width: 300px;
    border: 1px solid lightgray;
}

.creaform>form>input[type=submit] {
    margin-bottom: 20px;
}

.creaform>form>p {
    font-size: 0.8em;
    margin-bottom: 20px;
    margin: 5px;
    margin-bottom: 20px;
}

.creaform>form>select {
    margin: 5px;
    height: 35px;
    width: 304px;
    border: 1px solid lightgray;
    border-radius: 0;
    padding: 0;
}

.creaform>h2 {
    background-color: var(--main-menu-color);
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 50px;
    width: 100%;
    text-align: center;
    color: white;
    margin: 0;
    margin-bottom: 20px;
}

.creaform>input[type=checkbox] {
    margin-top: 20px;
    margin-bottom: 20px;
}

.product {
    margin-bottom: 40px;
    width: 70%;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
}

.product>h2 {
    height: 60px;
    vertical-align: middle;
    line-height: 50px;
    margin: 0;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    color: black;
    border-bottom: 1px solid lightgray;
}

.descr {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 40px;
}

.descr>img {
    max-width: 300px;
    height: auto;
    margin-left: 40px;
}

.descr>p {
    margin-left: 20px;
    margin-right: 80px;
}

.achatBox {
    height: 200px;
    min-width: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(223, 241, 240);
    margin-right: 30px;
}

.achatBox>p {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0;
    margin-bottom: 25px;
    padding: 0;
    color: rgb(28, 29, 124);
    font-family: 'Oswald', sans-serif;
}

.achatBox>a {
    padding: 10px;
    padding-right: 40px;
    padding-left: 40px;
    background-color: rgb(255, 167, 36);
    text-decoration: none;
    font-size: 1em;
    font-weight: bold;
    color: rgb(0, 0, 0);
    border-radius: 5px;
    box-shadow: 2px 2px 0px rgb(133, 133, 133);
}

.achatBox>a:hover {
    text-decoration: none;
    background-color: rgb(173, 146, 106);
    animation-name: configureButton;
    animation-duration: 0.4s;
}

.achatBox>a:visited {
    text-decoration: none;
}

.product .technical {
    display: flex;
    width: 100%;
}

.product .technical p {
    border-bottom: 1px solid lightgray;
    line-height: 30px;
}

.product .technical .techlabel {
    width: 20%;
    text-align: right;
}

.product .technical .techtext>p {
    margin-left: 20px;
}

.account {
    width: 65%;
    display: flex;
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: rgb(230, 230, 230);
}

.child:nth-child(2) {
    flex: 1;
}

.menuac {
    background-color: rgb(230, 230, 230);
    width: 200px;
}

.menuac>ul {
    list-style: none;
    list-style-position: inside;
    padding: 0;
    padding-top: 32px;
    margin: 0;
    margin-left: 8%;
    margin-right: 8%;
}

.menuac>ul li {
    padding-bottom: 10px;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 5px;
    border: 1px solid lightgray;
    background-color: var(--main-menu-color);
}

.menuac>ul li:hover {
    cursor: pointer;
    background-color: rgb(10, 10, 53);
}

.menuac>ul li>a {
    text-decoration: none;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px;
    color: var(--main-title-police-color);
}

.menuac>h2 {
    background-color: var(--main-menu-color);
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0;
}

.containeraccount {
    min-height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    background-color: rgb(255, 255, 255);
}

.containeraccount>table {
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom: 40px;
}

.containeraccount>table,
th,
td {
    border: 1px solid lightgray;
}

.containeraccount>table,
th {
    height: 50px;
}

.containeraccount th {
    background-color: #f5f5f5;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.containeraccount td {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.containeraccount>h2 {
    text-align: center;
    background-color: var(--main-menu-color);
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 0;
    color: var(--main-title-police-color);
}

.info {
    border: 1px solid rgb(189, 189, 189);
    margin-bottom: 40px;
    margin-top: 20px;
    padding-bottom: 20px;
}

.info>h3 {
    background-color: var(--main-menu-color);
    color: var(--main-title-police-color);
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 0;
    margin-bottom: 40px;
    padding-left: 10px;
}

.info>form {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding-left: 30px;
    padding-right: 30px;
}

.info-info>label>input {
    width: 200px;
    border: 0px solid rgb(189, 189, 189);
    border-bottom: 1px solid rgb(189, 189, 189);
    margin: 5px;
    padding-top: 10px;
    margin-top: 10px;
    padding-bottom: 10px;
    font-size: 1em;
    color: rgb(83, 83, 83);
}

.info-info>label {
    display: block;
    float: left;
    margin: 0;
    width: 200px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    color: black;
}

.info-adr>label>input {
    width: 200px;
    border: 0px solid rgb(189, 189, 189);
    border-bottom: 1px solid rgb(189, 189, 189);
    margin: 5px;
    padding-top: 10px;
    margin-top: 10px;
    padding-bottom: 10px;
    font-size: 1em;
    color: rgb(83, 83, 83);
}

.info-adr>label {
    display: block;
    float: left;
    width: 200px;
    margin: 0;
    margin-left: 0px;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    color: black;
}

.info-adr2>label>input {
    width: 200px;
    border: 0px solid rgb(189, 189, 189);
    border-bottom: 1px solid rgb(189, 189, 189);
    margin: 5px;
    padding-top: 10px;
    margin-top: 10px;
    padding-bottom: 10px;
    font-size: 1em;
    color: rgb(83, 83, 83);
}

.info-adr2>label {
    display: block;
    float: left;
    margin: 0;
    width: 200px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    color: black;
}

.containeraccount>form>input {
    height: 40px;
    width: 250px;
    margin-bottom: 10px;
    border: 1px solid rgb(189, 189, 189);
}

.containeraccount>form>label {
    width: 250px;
    padding-top: 5px;
    padding-bottom: 10px;
}

.containeraccount>p {
    padding-left: 20px;
    padding-right: 20px;
}

.panier {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 60%;
    min-height: 500px;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
}

.stepcontener {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: var(--main-bg-color);
}

.stepsection {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    min-width: 405px;
    min-height: 480px;
    margin-right: 1px;
    background-color: rgb(255, 255, 255);
    margin-top: 20px;
    color: var(--main-police-color);
}

.stepsection>form {
    width: 70%;
}

.stepsection input[type=text],
select,
textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

.stepsection label {
    padding: 12px 12px 12px 0;
    display: inline-block;
}

.stepsection.col-25 {
    float: left;
    width: 25%;
    margin-top: 6px;
}

.stepsection.col-75 {
    float: left;
    width: 75%;
    margin-top: 6px;
}

/* Clear floats after the columns */

.stepsection.row:after {
    content: "";
    display: table;
    clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */

@media screen and (max-width: 600px) {

    .stepsection.col-25,
    .col-75,
    input[type=submit] {
        width: 100%;
        margin-top: 0;
    }
}

.stepsection>h2 {
    width: 100%;
    text-align: center;
    background-color: var(--main-menu-color);
    color: var(--main-title-police-color);
    margin-top: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 1.3em;
    font-family: "Oswald", sans-serif;
}

.stepsection input [type=button],
input[type=submit],
input[type=reset] {
    background-color: rgb(185, 185, 185);
    border: none;
    color: rgb(51, 51, 51);
    text-decoration: none;
    cursor: pointer;
    width: 150px;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 10px;
    border-radius: 5px;
    padding-top: 8px;
    padding-bottom: 8px;
    text-decoration: none;
    font-size: 0.8em;
}

.stepsectionn ainput [type=button]:hover,
input[type=submit]:hover,
input[type=reset]:hover {
    background-color: rgb(146, 146, 146);
}

.largesect {
    width: 65%;
    min-height: 150px;
    text-align: center;
    background-color: rgb(255, 255, 255);
}

.pan {
    width: 65%;
    min-height: 150px;
    text-align: center;
    background-color: rgb(255, 255, 255);
    margin-top: 40px;
    margin-bottom: 40px;
}

.pan>h2 {
    width: 100%;
    text-align: center;
    background-color: var(--main-menu-color);
    color: var(--main-title-police-color);
    margin-top: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 1.4m;
    font-family: "Oswald", sans-serif;
}

.largesect>h2 {
    width: 100%;
    text-align: center;
    background-color: var(--main-menu-color);
    color: var(--main-title-police-color);
    margin-top: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 1.4m;
    font-family: "Oswald", sans-serif;
}

.paiement-container>ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.paiement-container>li {
    display: inline-block;
}

.paiement-container input[type="radio"][id^="cb"] {
    display: none;
}

.paiement-container label {
    border: 1px solid #fff;
    padding: 10px;
    display: block;
    position: relative;
    margin: 10px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.paiement-container label::before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: -5px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
    content: " ";
    background-color: rgb(255, 255, 255);
    transform: scale(1);
}

.paiement-container label img {
    height: auto;
    width: 100px;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
}

.paiement-container :checked+label {
    border-color: #ddd;
    box-shadow: 0 0 5px #333;
    transition-duration: 0.2s;
}

.paiement-container :checked+label::before {
    content: "✓";
    background-color: rgb(64, 175, 53);
    transform: scale(1);
}

.paiement-container :checked+label img {
    transform: scale(0.9);
}

.sortPanel {
    width: 300px;
    min-height: 300px;
    background-color: rgb(221, 221, 221);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.sortPanel>label {
    margin-top: 25px;
    width: 180px;
}

.sortPanel>select {
    width: 180px;
    height: 30px;
}

.sortPanel>h2 {
    width: 100%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0;
    background-color: var(--main-menu-color);
    color: var(--main-title-police-color);
}

.configurator {
    width: 50%;
    background-color: rgb(255, 255, 255);
    margin-top: 10px;
    margin-bottom: 20px;
    padding-bottom: 40px;
}

.configtable>table {
    margin-top: 25px;
    border: 1px solid rgb(197, 197, 197);
    border-spacing: 10px;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(255, 255, 255);
    border-collapse: collapse;
}

.configtable>th,
td {
    padding: 15px;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
}

.configtable th {
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgb(178, 212, 226);
}

.configtable>h2 {
    width: 99%;
    font-size: 1.5em;
    font-weight: bold;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    text-align: left;
    color: var(--main-title-police-color);
    background-color: rgb(69, 69, 71);
}

.configdescr {
    top: 0;
}

.configdescr>h2 {
    width: 99%;
    font-size: 1.5em;
    font-weight: bold;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    text-align: left;
    color: var(--main-title-police-color);
    background-color: rgb(69, 69, 71);
}

.configdescr>p {
    margin: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    background-color: rgb(197, 198, 199);
}

.configprice {
    width: 17%;
    background-color: rgb(255, 255, 255);
    margin-top: 10px;
    margin-bottom: 18px;
    height: 200px;
    display: flex;
    top: 0;
    position: sticky;
    margin-left: 10px;
    flex-direction: column;
    align-items: center;
    border: 2px solid rgb(69, 69, 71);
}

.configprice>p {
    text-align: right;
    margin: 0;
    margin-bottom: 10px;
    font-size: 1em;
    font-weight: bold;
}

.configprice>a {
    width: 50%;
    margin-top: 15px;
    padding: 10px;
    padding-right: 15px;
    padding-left: 15px;
    text-align: center;
    background-color: rgb(201, 0, 26);
    text-decoration: none;
    font-size: 1.1em;
    font-weight: bold;
    color: rgb(255, 255, 255);
    border-radius: 5px;
    margin-bottom: 20px;
}

.configprice>a:hover {
    text-decoration: none;
    background-color: rgb(207, 93, 108);
    animation-name: addtocartbutton;
    animation-duration: 0.4s;
}

@keyframes addtocartbutton {
    from {
        background-color: rgb(201, 0, 26);
    }

    to {
        background-color: rgb(207, 93, 108);
    }
}

.configprice>h3 {
    width: 100%;
    text-align: center;
    font-size: 1.3em;
    font-weight: bold;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    color: white;
    background-color: rgb(69, 69, 71);
}

.optionlist {
    text-align: center;
}

.optionlist select {
    width: 50%;
    height: 40px;
    margin-bottom: 10px;
}

.optioncat>h2 {
    width: 99%;
    font-size: 1.1em;
    font-weight: bold;
    margin: 0;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 10px;
    margin-top: 20px;
    text-align: left;
    color: var(--main-title-police-color);
    background-color: rgb(69, 69, 71);
}

.caselist img {
    max-width: 160px;
    margin-right: 20px;
    height: auto;
}

.caselist {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.presentationsection {
    display: flex;
    width: 100%;
    background-color: rgb(39, 38, 38);
    color: white;
    align-items: center;
    justify-content: center;
}

.presentationBox {
    width: 300px;
    height: 200px;
    border-left: 1px solid rgb(255, 255, 255);
}

.actu {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: rgb(255, 255, 255);
}

.articlectu {
    width: 32%;
    min-width: 300px;
    min-height: 400px;
    padding-bottom: 40px;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    text-align: center;
    margin: 20px;
}

.articlectu>article {
    min-height: 380px;
}

.articlectu>article>h3 {
    background-color: var(--main-menu-color);
    width: 100%;
    margin: 0;
    padding-top: 10px;
    font-size: 1.4em;
    padding-bottom: 10px;
    font-family: 'Oswald', sans-serif;
    color: var(--main-title-police-color);
    text-align: center;
}

.articlectu>article>h4 {
    background-color: rgb(255, 255, 255);
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    color: rgb(15, 16, 56);
    width: 94%;
    margin-left: 15px;
    text-align: left;
    font-weight: bold;
    font-family: 'Open sans', sans-serif;
    border-bottom: 1px solid var(--main-alt-color);
}

.articlectu>article>p {
    text-align: left;
    margin-left: 15px;
    margin-right: 15px;
    font-family: 'Open sans', sans-serif;
    font-size: 0.9em;
    color: var(--main-police-color);
}

.articlectu>article>p>img {
    max-width: 300px;
    height: auto;
    margin: 0;
    margin-left: 15px;
    float: right;
}

.articlectu>a {
    padding: 10px;
    padding-right: 15px;
    padding-left: 15px;
    text-decoration: none;
    font-size: 1em;
    font-weight: bold;
    color: var(--main-menu-color);
    border-radius: 15px;
    border: 1px solid var(--main-menu-color);
    background-color: rgb(255, 255, 255);
    text-align: center;
}

.defileParent {
    max-width: 300px;
    margin-left: 60px;
    margin-right: 0px;
    /* largeur de la fenêtre */
    overflow: hidden;
    /* masque tout ce qui dépasse */
}

.defile {
    display: inline-block;
    /* modèle de boîte en ligne */
    padding-right: 2em;
    /* un peu d'espace pour la transition */
    padding-left: 100%;
    /* placement à droite du conteneur */
    white-space: nowrap;
    /* pas de passage à la ligne */
    animation: defilement-rtl 20s infinite linear;
}

.defile:after {
    content: attr(data-text);
    position: absolute;
    white-space: nowrap;
    padding-left: 10px;
}

@keyframes defilement-rtl {
    0% {
        transform: translate3d(0, 0, 0);
        /* position initiale à droite */
    }

    100% {
        transform: translate3d(-100%, 0, 0);
        /* position finale à gauche */
    }
}

/* Customize the label (the container) */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-left: 20px;
    margin-bottom: 8px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container2 {
    display: inline;
    position: relative;
    padding-left: 35px;
    margin-left: 40px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* Hide the browser's default radio button */
.container input,
.container2 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: -16px;
    height: 16px;
    width: 16px;
    background-color: #d3d3d3;
    border-radius: 50%;
}

.checkmark2 {
    position: absolute;
    top: 0;
    left: 5px;
    height: 16px;
    width: 16px;
    background-color: #d3d3d3;
    border-radius: 50%;
}

.catItem>a {
    margin-left: 10px;
    margin-bottom: 40px;
    color: black;
}

.catItem {

    margin-bottom: 10px;

}


.childItem {
    margin-left: 40px;
    margin-top: 10px;
}


/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked~.checkmark,
.container2 input:checked~.checkmark2 {

    background-color: rgb(65, 124, 44);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after,
.checkmark2:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked~.checkmark:after,
.container2 input:checked~.checkmark2:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after,
.container2 .checkmark2:after {
    top: 5px;
    left: 5px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: white;
}



.image-gallery-icon {
    color: #fff;
    transition: all .3s ease-out;
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4;
    filter: drop-shadow(0 2px 2px #1a1a1a)
}

@media(hover: hover)and (pointer: fine) {
    .image-gallery-icon:hover {
        color: #337ab7
    }

    .image-gallery-icon:hover .image-gallery-svg {
        transform: scale(1.1)
    }
}

.image-gallery-icon:focus {
    outline: 2px solid #337ab7
}

.image-gallery-using-mouse .image-gallery-icon:focus {
    outline: none
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
    bottom: 0;
    padding: 20px
}

.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
    height: 28px;
    width: 28px
}

@media(max-width: 768px) {

    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
        padding: 15px
    }

    .image-gallery-fullscreen-button .image-gallery-svg,
    .image-gallery-play-button .image-gallery-svg {
        height: 24px;
        width: 24px
    }
}

@media(max-width: 480px) {

    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
        padding: 10px
    }

    .image-gallery-fullscreen-button .image-gallery-svg,
    .image-gallery-play-button .image-gallery-svg {
        height: 16px;
        width: 16px
    }
}

.image-gallery-fullscreen-button {
    right: 0
}

.image-gallery-play-button {
    left: 0
}

.image-gallery-left-nav,
.image-gallery-right-nav {
    padding: 50px 10px;
    top: 50%;
    transform: translateY(-50%)
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 120px;
    width: 60px
}

@media(max-width: 768px) {

    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
        height: 72px;
        width: 36px
    }
}

@media(max-width: 480px) {

    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
        height: 48px;
        width: 24px
    }
}

.image-gallery-left-nav[disabled],
.image-gallery-right-nav[disabled] {
    cursor: disabled;
    opacity: .6;
    pointer-events: none
}

.image-gallery-left-nav {
    left: 0
}

.image-gallery-right-nav {
    right: 0
}

.image-gallery {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: relative
}

.image-gallery.fullscreen-modal {
    background: #000;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5
}

.image-gallery.fullscreen-modal .image-gallery-content {
    top: 50%;
    transform: translateY(-50%)
}

.image-gallery-content {
    position: relative;
    line-height: 0;
    top: 0
}

.image-gallery-content.fullscreen {
    background: #000
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 80px)
}

.image-gallery-content.image-gallery-thumbnails-left .image-gallery-slide .image-gallery-image,
.image-gallery-content.image-gallery-thumbnails-right .image-gallery-slide .image-gallery-image {
    max-height: 100vh
}

.image-gallery-slide-wrapper {
    position: relative
}

.image-gallery-slide-wrapper.image-gallery-thumbnails-left,
.image-gallery-slide-wrapper.image-gallery-thumbnails-right {
    display: inline-block;
    width: calc(100% - 110px)
}

@media(max-width: 768px) {

    .image-gallery-slide-wrapper.image-gallery-thumbnails-left,
    .image-gallery-slide-wrapper.image-gallery-thumbnails-right {
        width: calc(100% - 87px)
    }
}

.image-gallery-slide-wrapper.image-gallery-rtl {
    direction: rtl
}

.image-gallery-slides {
    line-height: 0;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    text-align: center
}

.image-gallery-slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.image-gallery-slide.image-gallery-center {
    position: relative
}

.image-gallery-slide .image-gallery-image {
    width: 100%;
    object-fit: contain
}

.image-gallery-slide .image-gallery-description {
    background: rgba(0, 0, 0, .4);
    bottom: 70px;
    color: #fff;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal
}

@media(max-width: 768px) {
    .image-gallery-slide .image-gallery-description {
        bottom: 45px;
        font-size: .8em;
        padding: 8px 15px
    }
}

.image-gallery-bullets {
    bottom: 20px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 80%;
    z-index: 4
}

.image-gallery-bullets .image-gallery-bullets-container {
    margin: 0;
    padding: 0;
    text-align: center
}

.image-gallery-bullets .image-gallery-bullet {
    appearance: none;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    box-shadow: 0 2px 2px #1a1a1a;
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    outline: none;
    padding: 5px;
    transition: all .2s ease-out
}

@media(max-width: 768px) {
    .image-gallery-bullets .image-gallery-bullet {
        margin: 0 3px;
        padding: 3px
    }
}

@media(max-width: 480px) {
    .image-gallery-bullets .image-gallery-bullet {
        padding: 2.7px
    }
}

.image-gallery-bullets .image-gallery-bullet:focus {
    transform: scale(1.2);
    background: #337ab7;
    border: 1px solid #337ab7
}

.image-gallery-bullets .image-gallery-bullet.active {
    transform: scale(1.2);
    border: 1px solid #fff;
    background: #fff
}

@media(hover: hover)and (pointer: fine) {
    .image-gallery-bullets .image-gallery-bullet:hover {
        background: #337ab7;
        border: 1px solid #337ab7
    }

    .image-gallery-bullets .image-gallery-bullet.active:hover {
        background: #337ab7
    }
}

.image-gallery-thumbnails-wrapper {
    position: relative
}

.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
    touch-action: pan-y
}

.image-gallery-thumbnails-wrapper.thumbnails-swipe-vertical {
    touch-action: pan-x
}

.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
    direction: rtl
}

.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left,
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
    display: inline-block;
    vertical-align: top;
    width: 100px
}

@media(max-width: 768px) {

    .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left,
    .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
        width: 81px
    }
}

.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left .image-gallery-thumbnails,
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right .image-gallery-thumbnails {
    height: 100%;
    width: 100%;
    left: 0;
    padding: 0;
    position: absolute;
    top: 0
}

.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left .image-gallery-thumbnails .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right .image-gallery-thumbnails .image-gallery-thumbnail {
    display: block;
    margin-right: 0;
    padding: 0
}

.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left .image-gallery-thumbnails .image-gallery-thumbnail+.image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right .image-gallery-thumbnails .image-gallery-thumbnail+.image-gallery-thumbnail {
    margin-left: 0;
    margin-top: 2px
}

.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left,
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
    margin: 0 5px
}

@media(max-width: 768px) {

    .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left,
    .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
        margin: 0 3px
    }
}

.image-gallery-thumbnails {
    overflow: hidden;
    padding: 5px 0
}

@media(max-width: 768px) {
    .image-gallery-thumbnails {
        padding: 3px 0
    }
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    white-space: nowrap
}

.image-gallery-thumbnail {
    display: inline-block;
    border: 4px solid transparent;
    transition: border .3s ease-out;
    width: 100px;
    background: transparent;
    padding: 0
}

@media(max-width: 768px) {
    .image-gallery-thumbnail {
        border: 3px solid transparent;
        width: 81px
    }
}

.image-gallery-thumbnail+.image-gallery-thumbnail {
    margin-left: 2px
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
    display: block;
    position: relative
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
    outline: none;
    border: 4px solid #337ab7
}

@media(max-width: 768px) {

    .image-gallery-thumbnail.active,
    .image-gallery-thumbnail:focus {
        border: 3px solid #337ab7
    }
}

@media(hover: hover)and (pointer: fine) {
    .image-gallery-thumbnail:hover {
        outline: none;
        border: 4px solid #337ab7
    }
}

@media(hover: hover)and (pointer: fine)and (max-width: 768px) {
    .image-gallery-thumbnail:hover {
        border: 3px solid #337ab7
    }
}

.image-gallery-thumbnail-label {
    box-sizing: border-box;
    color: #fff;
    font-size: 1em;
    left: 0;
    line-height: 1em;
    padding: 5%;
    position: absolute;
    top: 50%;
    text-shadow: 0 2px 2px #1a1a1a;
    transform: translateY(-50%);
    white-space: normal;
    width: 100%
}

@media(max-width: 768px) {
    .image-gallery-thumbnail-label {
        font-size: .8em;
        line-height: .8em
    }
}

.image-gallery-index {
    background: rgba(0, 0, 0, .4);
    color: #fff;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4
}

@media(max-width: 768px) {
    .image-gallery-index {
        font-size: .8em;
        padding: 5px 10px
    }
}